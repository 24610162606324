import { AccessSetting } from "app/common/api/trustedConnection";
import { ICheckboxItem } from "app/components/basic/Checkbox/Checkbox";
import { IDateValue } from "app/components/basic/Date";
import { IDropdownItemTemplate } from "app/components/basic/Dropdown";
import { IFileObject } from "app/components/basic/FileInput/type";
import { IRadioItem } from "app/components/basic/Radio";
import { IRadioBoxItem } from "app/components/basic/Radio.Box";
import { TAppointPersonDO } from "app/components/composites/AppointPerson";
import { TAppointPersonDOV2 } from "app/components/composites/AppointPerson/AppointPersonGroupV2";
import { IDoneeCardItem } from "app/components/composites/DoneeCard";
import { TAcpType, TAcpValue } from "app/modules/acp/pages/form/NhsPage/components/types";
import { TEWillsType, TEWillsValue } from "app/modules/eWills/components/AddItemGroup/types";
import { IPropertyAllocationItem } from "app/modules/eWills/components/PropertyAllocation";
import { IVehicleDetails } from "app/modules/hdb-car-parking/hooks/useHdbParkingForm/type";
import { Moment } from "moment";
import "./Form.scss";

export type IInputValidatorNames =
	| "isEmail"
	| "isOPGOEmail"
	| "isRequired"
	| "isNumberString"
	| "isNRIC"
	| "isNRICLocal"
	| "isNRICShape"
	| "isLocalPhoneNumber"
	| "isMobileNumber"
	| "isNot"
	| "isNumber"
	| "isMoney"
	| "isZero"
	| "isMaxValue"
	| "isMinLength"
	| "isMaxLength"
	| "isVehicleNumber"
	| "isInternationalPhoneFormat"
	| "isTodayOrBefore"
	| "isCaseInsensitiveMatch"
	| "isAlphanumeric"
	| "includesHealthcareSpokesperson"
	| "isMaxTwoHealthcareSpokespersons"
	| "isValidDob"
	| "isUnique"
	| "isValidatePropertyAllocation"
	| "isValidateAddress"
	| "isValidateCurrency"
	| "isNotIn"
	| "isAboveAge"
	| "isCarplate"
	| "match"
	| "doesNotMatch";

export type IInputValidatorFunction =
	| ((value: string, message: string) => string | undefined)
	| ((value: string, message: string, param?: any) => string | undefined);

export type IConstraint = [IInputValidatorNames, string] | [IInputValidatorNames, any, string];

export enum FormType {
	ACP = "ACP",
}

export interface IBasicFormField {
	type:
		| "text"
		| "string"
		| "number"
		| "numberString"
		| "email"
		| "phone"
		| "nric"
		| "date"
		| "radio"
		| "dropdown"
		| "trustedPerson"
		| "fileInput"
		| "checkbox"
		| "appointPerson"
		| "unitNumber"
		| "textArea"
		| "addExecutors"
		| TEWillsType
		| "vehicleDetails"
		| TAcpType
		| "yesnoSelector";
	constraints?: IConstraint[];
	errorMessage?: string;
	disableValidation?: boolean;
	maxChar?: number;
	radioItems?: IRadioItem[];
	checkboxItems?: ICheckboxItem[];
	dropdownItems?: IDropdownItemTemplate[];
	radioBoxItems?: IRadioBoxItem[];
	doneeCardItems?: IDoneeCardItem[];
	/** props for PropertyAllocation item */
	propertyAllocation?: IPropertyAllocationItem;
	/** placeholder text for textArea */
	placeholder?: string;
	/** props for transforming the input value to uppercase */
	transformUppercase?: boolean;
}

export type TFormValue =
	| TAppointPersonDO[]
	| IFileObject[]
	| number[]
	| string[]
	| { [key: string]: string }
	| string
	| number
	| AccessSetting
	| Moment
	| IDateValue
	| TAppointPersonDOV2[]
	| TEWillsValue
	| TEWillsValue[]
	| IVehicleDetails[]
	| TAcpValue;

export interface IFormField extends IBasicFormField {
	key: string;
	value: TFormValue;
}
