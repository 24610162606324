import { IDateValue } from "app/components/basic/Date";
import { formatDateValue } from "app/components/basic/Date/utils";
import { cleanRadioInput, IRadioItem } from "app/components/basic/Radio";
import { IRadioBoxItem } from "app/components/basic/Radio.Box";
import { TAppointPersonDO } from "app/components/composites/AppointPerson";
import { TAppointPersonDOV2 } from "app/components/composites/AppointPerson/AppointPersonGroupV2";
import determineSessionStatus, {
	SessionStatusType,
} from "app/components/composites/SessionModal/determineSessionStatus";
import { useAlert } from "app/hooks/useAlert";
import { AcpBaseUrl } from "app/modules/acp/constants";
import { TAcpValue } from "app/modules/acp/pages/form/NhsPage/components/types";
import { TEWillsValue } from "app/modules/eWills/components/AddItemGroup/types";
import { validatePropertyAllocation } from "app/modules/eWills/components/PropertyAllocation";
import { eWillsUrl } from "app/modules/eWills/constants";
import { hdbParkingUrl } from "app/modules/hdb-car-parking/constants";
import { IVehicleDetails } from "app/modules/hdb-car-parking/hooks/useHdbParkingForm/type";
import { getCookie } from "app/utils/cookies";
import _ from "lodash";
import Router from "next/router";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import "./Form.scss";
import {
	validateAddressField,
	validateAppointPersonValue,
	validateCheckboxValue,
	validateCurrency,
	validateFileInputValue,
	validateInputValue,
	validateRadioValue,
} from "./Form.Validation";
import { IBasicFormField, IConstraint, IFormField, TFormValue } from "./types";
import { formatUnitNumberValue } from "./utils";

export const FORM_FIELD_WITH_ERROR_CLASSNAME = "field__has-error";
const DEFAULT_FORM_FIELD_ERROR_CLASSNAME = "error";

export interface IFormSchema {
	[key: string]: IBasicFormField;
}
export interface IForm {
	form: IFormFunctions;
}

export interface IFormFunctions {
	fields: IFormFieldHashMap;
	submittingFields: IData;
	loading: boolean;
	errors: IData;
	focusField: (key: string) => void;
	focusFirstError: () => void;
	getFields: () => IData;
	getField: (key: string) => IFormField;
	getValue: (key: string) => any;
	getError: (key: string) => string | undefined;
	updateFieldValue: (
		key: string,
		value:
			| string[]
			| string
			| TAppointPersonDO[]
			| IDateValue
			| TAppointPersonDOV2[]
			| TEWillsValue
			| TEWillsValue[]
			| IVehicleDetails[]
			| TAcpValue,
	) => void;
	updateFieldsObject: (key: string, newFieldKeyValue: object, isKeepError?: boolean) => void;
	submitForm: (
		submitCallback: () => void | Promise<void>,
		additionalValidations?: () => Promise<boolean>,
	) => Promise<boolean>;
	validateFieldValue: (field: IFormField) => void;
	updateFieldError: (key: string, error?: string | JSX.Element) => void;
	setFieldShouldValidate: (key: string, shouldValidate: boolean) => void;
	setMultipleFieldShouldValidate: (newValidationMap: IData) => void;
	updateFieldConstraints: (key: string, newConstraints: IConstraint[]) => void;
	validateForm: () => boolean;
	ignoreChangesMade: () => void;
	removeListeners: () => void;
	hasChanges: () => boolean;
	hasSubmittingField: () => boolean;
	updateSubmittingForAllFields: (isSubmitting: boolean) => void;
	updateSubmittingStatusForField: (key: string, newSubmittingState: any) => void;
	resetFieldError: (key: string) => void;
	resetAllFieldError: () => void;
	warnChangesLostIfProceed: (discardChangesCallback: () => void, keepChangesCallback?: () => void) => void;
	setupFormFields: (fieldsForSchema?: IFormSchema, initialDataToLoad?: IData, keyToFocus?: string) => void;
	updateFormField: (key: string, field: IBasicFormField) => void;
	forceWarnIfExit: (warn: boolean) => void;
	setCanExit: Dispatch<SetStateAction<boolean>>;

	/**
	 * Adds to a list of callbacks to perform after form.validateForm.
	 * Callbacks added must have unique names
	 * @param onSuccess callback to perform on validation success
	 * @param onFail callback to perform on validation fail
	 */
	setValidationCallback: (
		onSuccess: () => void,
		onFail?: () => void,
		onSuccessName?: string,
		onFailName?: string,
	) => void;
	removeValidationCallback: (
		onSuccess?: () => void,
		onFail?: () => void,
		onSuccessName?: string,
		onFailName?: string,
	) => void;

	/**
	 * Adds a list of prevalidation actions to be run before actual validation takes place
	 */
	setPrevalidationAction: (prevalidationAction: () => boolean, name?: string) => void;
	removePrevalidationAction: (prevalidationAction: () => boolean, name?: string) => void;

	/**
	 * Adds to a list of additional validations to perform in form.validateForm.
	 * Callbacks added must have unique names
	 * @param additionalValidation return true for successful validation
	 */
	setAdditionalValidation: (additionalValidation: () => boolean, name?: string) => void;
	removeAdditionalValidation: (additionalValidation: () => boolean, name?: string) => void;

	validateSingleFieldForError: (field: IFormField) => string;
}

export interface IFormFieldHashMap {
	[key: string]: IFormField;
}

export interface IData {
	[key: string]: any;
}

export type { IBasicFormField, IFormField, TFormValue };

export const useForm = (fieldsForSchema?: IFormSchema, initialDataToLoad?: IData, keyToFocus?: string): IForm => {
	const isFirstLoad = useRef(true);
	const initialSnapshot = useRef<IData>({});

	const [fields, setFields] = useState<IFormFieldHashMap>({});
	const [loading, setLoading] = useState(true);
	const [canExit, setCanExit] = useState(false);
	const [warnChangesModalOpen, setWarnChangesModalOpen] = useState(false);
	const [warnIfExit, forceWarnIfExit] = useState<boolean | undefined>();
	const [submittingFields, setSubmittingFields] = useState<IData>({});
	const [errors, setErrors] = useState<IData>({});
	const [updatedSnapshot, setUpdatedSnapshot] = useState<IData>({});
	const [onValidateSuccess, setOnValidateSuccess] = useState<{ [key: string]: () => void }>({});
	const [onValidateFail, setOnValidateFail] = useState<{ [key: string]: () => void }>({});
	const [additionalValidations, setAdditionalValidations] = useState<{
		[key: string]: () => boolean;
	}>({});
	const [preValidationActions, setPrevalidationActions] = useState<{
		[key: string]: () => boolean;
	}>({});

	const { alertModal, dismissAlertModal } = useAlert();

	// =========================================================================
	// MISC
	// =========================================================================
	const hasChanges = useCallback((): boolean => {
		if (warnIfExit !== undefined) {
			return warnIfExit;
		}
		return !_.isEqual(initialSnapshot.current, updatedSnapshot);
	}, [updatedSnapshot, warnIfExit]);

	const onUnload = useCallback(
		(event: BeforeUnloadEvent): string | void => {
			const expiry = getCookie("expiry");
			const action = determineSessionStatus(expiry);
			if (action !== SessionStatusType.timeout && !canExit && hasChanges()) {
				(event || window.event).returnValue = "hasChanges";
				return "hasChanges";
			}
		},
		[canExit, hasChanges],
	);

	const warnChangesLostIfProceed = useCallback(
		(callback: () => void, keepChangesCallback?: () => void) => {
			const discardAction = () => {
				setCanExit(true);
				setWarnChangesModalOpen(false);
				dismissAlertModal();
				callback();
			};

			const keepAction = () => {
				dismissAlertModal();
				setWarnChangesModalOpen(false);
				if (keepChangesCallback) {
					keepChangesCallback();
				}
			};

			const showWarningModal = () => {
				alertModal({
					id: "form-modal-lose-change",
					title: "Leave and lose changes?",
					subTitle: "You will lose any changes that you have made.",
					closeCallback: keepAction,
					button1: ["Leave", "primary", discardAction],
					button2: ["Stay", "secondary", keepAction],
				});
			};

			if (!canExit && hasChanges()) {
				showWarningModal();
				setWarnChangesModalOpen(true);
				return;
			} else {
				callback();
			}
		},
		[alertModal, canExit, dismissAlertModal, hasChanges],
	);

	const onRouterChange = useCallback(
		(url: string): void => {
			// logout or redirect
			if (url.includes("singpass")) {
				window.removeEventListener("beforeunload", onUnload);
				Router.events.off("routeChangeStart", onRouterChange);
			} else if (
				url.includes(eWillsUrl.landingPage) ||
				url.includes(hdbParkingUrl.landingPage) ||
				url.includes(AcpBaseUrl) ||
				url.includes("/death-or-stillbirth-certificate")
			) {
				return;
			} else if (!canExit && hasChanges() && !warnChangesModalOpen) {
				warnChangesLostIfProceed(() => void Router.push(url));
				throw new Error(); // to prevent Next Router from continuing
			}
		},
		[canExit, hasChanges, warnChangesModalOpen, onUnload, warnChangesLostIfProceed],
	);

	const addListeners = useCallback(() => {
		window.addEventListener("beforeunload", onUnload);
		Router.events.on("routeChangeStart", onRouterChange);
	}, [onRouterChange, onUnload]);

	const removeListeners = useCallback(() => {
		window.removeEventListener("beforeunload", onUnload);
		Router.events.off("routeChangeStart", onRouterChange);
	}, [onRouterChange, onUnload]);

	const ignoreChangesMade = useCallback(() => {
		removeListeners();
	}, [removeListeners]);

	// =========================================================================
	//	Focus Helpers
	// =========================================================================
	const focusField = useCallback(
		(key: string): void => {
			const focusInitial = (): void => {
				try {
					const elementCSSToFocus = `.form-field-${key} input, .form-field-${key} .first-focusable`;
					const element: any = document.querySelectorAll(elementCSSToFocus)![0];
					if (element) {
						element.focus();
					}
				} catch {
					return;
				}
			};

			if (keyToFocus) {
				setTimeout(focusInitial, 0);
			}
		},
		[keyToFocus],
	);

	const focusFirstError = useCallback((): void => {
		const focusError = (): void => {
			try {
				const navElement = document.getElementById("mylegacy_navbar") as HTMLElement;
				const element = document.querySelector(
					`.${FORM_FIELD_WITH_ERROR_CLASSNAME}, .${DEFAULT_FORM_FIELD_ERROR_CLASSNAME}`,
				) as HTMLElement;

				//If the element is wrapped by grid row => focus row, otherwise will be element
				const focusElement = element.closest(`.row`) || element;
				const offset = navElement.offsetHeight;

				const bodyRect = document.body.getBoundingClientRect().top;
				const focusElementRect = focusElement.getBoundingClientRect().top;

				const focusElementPosition = focusElementRect - bodyRect;
				const offsetPosition = focusElementPosition - offset;

				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			} catch {
				return;
			}
		};
		setTimeout(focusError, 0);
	}, []);

	// =========================================================================
	//	Get Values
	// =========================================================================
	const getValue = useCallback(
		(key: string): TFormValue => {
			try {
				const { value, type } = fields[key];

				if (type === "date") {
					return formatDateValue(value as string);
				}

				if (type === "unitNumber") {
					return formatUnitNumberValue(value as string);
				}

				if (type === "number") {
					return +value;
				}

				if (typeof value === "string") {
					return value.trim();
				}

				return value;
			} catch (e) {
				return "";
			}
		},
		[fields],
	);

	const getField = useCallback(
		(key: string): IFormField => {
			return fields[key] || { key: "", value: "", type: "text" };
		},
		[fields],
	);

	const getFields = useCallback((): IData => {
		const results: IData = {};
		for (const key of Object.keys(fields)) {
			results[key] = getValue(key);
		}
		return results;
	}, [fields, getValue]);

	// =========================================================================
	//	Errors
	// =========================================================================
	const updateFieldError = useCallback((key: string, error?: string | JSX.Element): void => {
		setErrors((errors) => ({ ...errors, [key]: error }));
	}, []);

	const resetFieldError = useCallback(
		(key: string): void => {
			updateFieldError(key, "");
		},
		[updateFieldError],
	);

	const resetAllFieldError = useCallback((): void => {
		setErrors((errors) => {
			const resetErrors: IData = {};
			for (const key of Object.keys(errors)) {
				resetErrors[key] = "";
			}

			return resetErrors;
		});
	}, []);

	const getError = useCallback(
		(key: string): string | undefined => {
			return errors[key];
		},
		[errors],
	);

	// =========================================================================
	//	Set Fields
	// =========================================================================
	const setInitialValue = useCallback((field: IBasicFormField, loadedValue?: any): TFormValue => {
		if (field.type === "date") {
			return loadedValue ? formatDateValue(loadedValue) : { day: "", month: "", year: "" };
		}

		if (field.type === "unitNumber") {
			return loadedValue ? formatUnitNumberValue(loadedValue) : [];
		}

		if (loadedValue && checkStringValue(loadedValue) !== undefined) {
			return loadedValue;
		}

		if (
			field.type === "fileInput" ||
			field.type === "trustedPerson" ||
			field.type === "checkbox" ||
			field.type === "appointPerson"
		) {
			return [];
		}

		if (field.type !== "radio") {
			return "";
		}

		const items: IRadioItem[] | IRadioBoxItem[] = field.radioItems || field.radioBoxItems || [];
		const defaultRadioItem: IRadioItem | undefined =
			items.filter((item: IRadioItem | IRadioBoxItem) => item.modifier === "default")[0] || undefined;
		if (defaultRadioItem) {
			return defaultRadioItem.label;
		}
		return "";
	}, []);

	const updateFormField = useCallback(
		(key: string, field: IBasicFormField): void => {
			setFields((fields) => {
				const decoratedField: IFormField = { key, value: setInitialValue(field), ...field };
				initialSnapshot.current = { ...fields, [key]: decoratedField.value };
				setUpdatedSnapshot({ ...fields, [key]: decoratedField.value });

				return { ...fields, [key]: decoratedField };
			});

			resetFieldError(key);
		},
		[resetFieldError, setInitialValue],
	);

	const updateFieldsObject = useCallback(
		(key: string, newFieldKeyValue: object, isKeepError?: boolean): void => {
			setFields((prevFields) => {
				const { type } = prevFields[key];

				if (type === "radio" || type === "dropdown" || type === "checkbox" || type === "appointPerson") {
					if (!isKeepError) {
						updateFieldError(key, "");
					}
				}

				return {
					...prevFields,
					[key]: { ...prevFields[key], ...newFieldKeyValue },
				};
			});

			const updatingKey = Object.keys(newFieldKeyValue)[0];
			if (updatingKey === "value") {
				setUpdatedSnapshot((prev) => ({
					...prev,
					[key]: newFieldKeyValue[updatingKey],
				}));
			}
		},
		[updateFieldError],
	);

	const updateFieldValue = useCallback(
		(
			key: string,
			value:
				| string[]
				| string
				| TAppointPersonDO[]
				| IDateValue
				| TAppointPersonDOV2[]
				| TEWillsValue
				| TEWillsValue[]
				| IVehicleDetails[]
				| TAcpValue,
		): void => {
			updateFieldsObject(key, { value });
		},
		[updateFieldsObject],
	);

	const updateFieldConstraints = useCallback(
		(key: string, newConstraints: IConstraint[]): void => {
			updateFieldsObject(key, { constraints: newConstraints });
		},
		[updateFieldsObject],
	);

	const setupFormFields = useCallback(
		(fieldsForSchema?: IFormSchema, initialDataToLoad?: IData, keyToFocus?: string): void => {
			if (!fieldsForSchema) {
				return;
			}

			setCanExit(false);
			setLoading(true);

			const formFields: IFormFieldHashMap = {};
			const snapshot: IData = {};
			const errors: IData = {};
			const submittingStatus: IData = {};
			const data = checkStringValue(initialDataToLoad) || {};

			for (const key of Object.keys(fieldsForSchema)) {
				const fieldData = fieldsForSchema[key];

				const initialValue = setInitialValue(fieldData, data[key]);
				const decoratedFieldProperties = { key, value: initialValue };

				formFields[key] = {
					...fieldData,
					...decoratedFieldProperties,
				};

				errors[key] = fieldData.errorMessage ? fieldData.errorMessage : "";

				// set currencyinput amount with default values
				snapshot[key] = setupInitialSnapshot(initialValue, key);

				submittingStatus[key] = false;
			}

			setFields(formFields);
			setErrors(errors);
			initialSnapshot.current = snapshot;
			setUpdatedSnapshot(snapshot);
			setSubmittingFields(submittingStatus);
			setLoading(false);

			if (keyToFocus) {
				focusField(keyToFocus);
			}
		},
		[focusField, setInitialValue],
	);

	const checkStringValue = (value: any) => {
		if (typeof value === "string") {
			if (value === "undefined" || value === "null") {
				return undefined;
			}
		}
		if (value) {
			return value;
		}
		return undefined;
	};
	// =========================================================================
	//	snapshots
	// =========================================================================
	const setupInitialSnapshot = (initialValue: TFormValue, key: string) => {
		return initialValue instanceof Array ? initialValue.slice(0) : initialValue;
	};
	// =========================================================================
	//	Validation
	// =========================================================================
	const validateSingleFieldForError = useCallback((field: IFormField): string => {
		if (typeof field.value === "string") {
			field.value = field.value.trim();
		}

		if (field.type === "radio") {
			field.value = cleanRadioInput(field.value as string);
			return validateRadioValue(field);
		} else if (field.type === "fileInput") {
			return validateFileInputValue(field);
		} else if (field.type === "checkbox") {
			return validateCheckboxValue(field);
		} else if (field.type === "appointPerson") {
			return validateAppointPersonValue(field);
		} else if (field.type === "propertyAllocation") {
			return validatePropertyAllocation(field);
		} else if (field.type === "privateFields" || field.type === "hdbFields") {
			return validateAddressField(field);
		} else if (field.type === "currencyInput") {
			return validateCurrency(field);
		} else {
			return validateInputValue(field);
		}
	}, []);

	const validateFieldValue = useCallback(
		(field: IFormField): void => {
			updateFieldError(field.key, validateSingleFieldForError(field));
		},
		[updateFieldError, validateSingleFieldForError],
	);

	const setFieldShouldValidate = useCallback(
		(key: string, shouldValidate: boolean): void => {
			updateFieldsObject(key, { disableValidation: !shouldValidate });
		},
		[updateFieldsObject],
	);

	const setMultipleFieldShouldValidate = useCallback((newValidationMap: IData): void => {
		setFields((fields) => {
			const updatedFields = { ...fields };
			for (const key of Object.keys(newValidationMap)) {
				updatedFields[key].disableValidation = !newValidationMap[key];
			}
			return updatedFields;
		});
	}, []);

	const setPrevalidationAction = (prevalidationActions: () => boolean, name?: string) => {
		setPrevalidationActions((callbacks) => {
			return {
				...callbacks,
				[name ?? prevalidationActions.name]: prevalidationActions,
			};
		});
	};

	const removePrevalidationAction = (prevalidationActions: () => boolean, name?: string) => {
		setPrevalidationActions((callbacks) => {
			const updatedCallbacks = { ...callbacks };
			delete updatedCallbacks[name ?? prevalidationActions.name];
			return updatedCallbacks;
		});
	};
	const validateForm = useCallback((): boolean => {
		const newFields: IFormFieldHashMap = {};
		const newErrors: IData = {};
		let formIsValid = true;

		formIsValid = Object.values(preValidationActions)
			.map((callback) => callback())
			.every((validation) => validation === true);

		for (const key of Object.keys(fields)) {
			const field = fields[key];
			let hasError = "";
			if (!field.disableValidation) {
				hasError = validateSingleFieldForError(field);
			}
			newFields[field.key] = field;
			newErrors[field.key] = hasError;
			if (hasError) {
				formIsValid = false;
			}
		}

		setFields(newFields);
		setErrors(newErrors);

		if (!formIsValid) {
			focusFirstError();
		}

		if (formIsValid) {
			formIsValid = Object.values(additionalValidations)
				.map((callback) => callback())
				.every((validation) => validation === true);
		}
		if (formIsValid) {
			for (const callback of Object.values(onValidateSuccess)) {
				callback();
			}
		} else {
			Object.values(onValidateFail).forEach((callback) => callback());
		}

		return formIsValid;
	}, [
		additionalValidations,
		fields,
		focusFirstError,
		onValidateFail,
		onValidateSuccess,
		validateSingleFieldForError,
		preValidationActions,
	]);

	const setValidationCallback = (
		onSuccess: () => void,
		onFail?: () => void,
		onSuccessName?: string,
		onFailName?: string,
	) => {
		setOnValidateSuccess((callbacks) => {
			return { ...callbacks, [onSuccessName ?? onSuccess.name]: onSuccess };
		});
		if (onFail) {
			setOnValidateFail((callbacks) => {
				return { ...callbacks, [onFailName ?? onFail.name]: onFail };
			});
		}
	};

	const setAdditionalValidation = (additionalValidation: () => boolean, name?: string) => {
		setAdditionalValidations((callbacks) => {
			return {
				...callbacks,
				[name ?? additionalValidation.name]: additionalValidation,
			};
		});
	};

	const removeValidationCallback = (
		onSuccess?: () => void,
		onFail?: () => void,
		onSuccessName?: string,
		onFailName?: string,
	) => {
		if (onSuccess) {
			setOnValidateSuccess((callbacks) => {
				const updatedCallbacks = { ...callbacks };
				delete updatedCallbacks[onSuccessName ?? onSuccess.name];
				return updatedCallbacks;
			});
		}

		if (onFail) {
			setOnValidateFail((callbacks) => {
				const updatedCallbacks = { ...callbacks };
				delete updatedCallbacks[onFailName ?? onFail.name];
				return updatedCallbacks;
			});
		}
	};

	const removeAdditionalValidation = (additionalValidation: () => boolean, name?: string) => {
		setAdditionalValidations((callbacks) => {
			const updatedCallbacks = { ...callbacks };
			delete updatedCallbacks[name ?? additionalValidation.name];
			return updatedCallbacks;
		});
	};
	// =============================================================================
	//	Submission
	// =============================================================================
	const hasSubmittingField = useCallback((): boolean => {
		for (const key of Object.keys(submittingFields)) {
			if (submittingFields[key]) {
				return true;
			}
		}
		return false;
	}, [submittingFields]);

	const updateSubmittingForAllFields = useCallback(
		(isSubmitting: boolean): void => {
			const updatedSubmittingFieldStatus: IData = {};
			for (const key of Object.keys(fields)) {
				updatedSubmittingFieldStatus[key] = isSubmitting;
			}
			setSubmittingFields(updatedSubmittingFieldStatus);
		},
		[fields],
	);

	const updateSubmittingStatusForField = useCallback(
		(key: string, newSubmittingState: any): void => {
			const updatedSubmittingFieldStatus: IData = {
				...submittingFields,
				[key]: newSubmittingState,
			};
			setSubmittingFields(updatedSubmittingFieldStatus);
		},
		[submittingFields],
	);

	const submitForm = useCallback(
		async (
			submitCallback: () => void | Promise<void>,
			additionalValidations?: () => Promise<boolean>,
		): Promise<boolean> => {
			setCanExit(true);
			updateSubmittingForAllFields(true);

			const validationResult = validateForm();
			const additionalValidationResult = (await additionalValidations?.()) ?? true;
			if (validationResult && additionalValidationResult) {
				try {
					removeListeners();
					await submitCallback();
					updateSubmittingForAllFields(false);
					return true; // submit success;
				} catch (e) {
					// does not explicitly handle error but stops error from bubbling up
				}
			}
			updateSubmittingForAllFields(false);
			setCanExit(false);
			return false; // submit fail;
		},
		[updateSubmittingForAllFields, validateForm, removeListeners],
	);

	useEffect(() => {
		if (isFirstLoad.current) {
			setupFormFields(fieldsForSchema, initialDataToLoad, keyToFocus);
			isFirstLoad.current = false;
		}
	}, [fieldsForSchema, initialDataToLoad, keyToFocus, setupFormFields]);

	useEffect(() => {
		if (!canExit) {
			addListeners();
		}
		return (): void => {
			removeListeners();
		};
	}, [updatedSnapshot, canExit, addListeners, removeListeners]);

	const form: IFormFunctions = {
		fields,
		submittingFields,
		errors,
		updateSubmittingForAllFields,
		updateSubmittingStatusForField,
		focusField,
		focusFirstError,
		loading,
		getFields,
		getField,
		getValue,
		getError,
		updateFieldValue,
		submitForm,
		validateFieldValue,
		updateFieldError,
		setFieldShouldValidate,
		setMultipleFieldShouldValidate,
		updateFieldConstraints,
		validateForm,
		updateFieldsObject,
		ignoreChangesMade,
		hasChanges,
		hasSubmittingField,
		resetFieldError,
		resetAllFieldError,
		warnChangesLostIfProceed,
		setupFormFields,
		updateFormField,
		forceWarnIfExit,
		setCanExit,
		setValidationCallback,
		removeValidationCallback,
		setAdditionalValidation,
		removeAdditionalValidation,
		setPrevalidationAction,
		removePrevalidationAction,
		validateSingleFieldForError,
		removeListeners,
	};

	return { form };
};
