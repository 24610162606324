export const SelectExecutorErrors = {
	MORE_THAN_2: "Select 2 persons at most.",
	ZERO_EXECUTOR: "Select or add at least 1 person.",
	ZERO_REPLACEMENT_EXECUTOR: "Select or add 1 person.",
	ADDED_BUT_NOT_SELECTED: "To confirm you do not want the person to be an executor, select Remove.",
	YES_OR_NO: "Select Yes or No.",
	SELECT_SUBSTITUTE: "Select who the replacement executor should be a substitute for.",
};

export enum ExecutorsKey {
	SELECT_EXECUTOR = "selectExecutor",
	ADDITIONAL_EXECUTORS = "addExecutor",
	HAS_REPLACEMENT_EXECUTOR = "hasReplacementExecutor",
	REPLACEMENT_EXECUTOR = "replacementExecutor",
	OTHER_REPLACEMENT_EXECUTOR = "otherReplacementExecutor",
	REPLACEMENT_EXECUTOR_TO_TAKE_PLACE = "replacementExecutorToTakePlace",
}
